import { useLocation } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import {
  GetUserByIdQuery,
  GetUserByIdQueryVariables,
  LeakFinderSettingsInput,
} from "../../API";
import { useAuthenticatedUser } from "../../common/hooks/useAuthenticatedUser";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { GET_USER_BY_ID } from "../../common/operations/queries";

export const DEFAULT_CAMERA_FOV = 27.5;

export const DEFAULT_WINDSPEED_UNIT = "mph";

export const useLazyGetUser = () => {
  const route = useLocation();
  const customerIdFromRouteState = route.state?.customerId as string;

  const { userSettings, user, customerId } = useAuthenticatedUser();

  const selectedCustomerId =
    customerIdFromRouteState ?? customerId ?? useCustomerIdGuard();

  const userId = `U#${user?.attributes?.sub as string}`;

  const [getUser, { loading }] = useLazyQuery<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >(GET_USER_BY_ID, {
    fetchPolicy: "network-only",
  });

  const fetchUser = async () => {
    return await getUser({
      variables: {
        customerId: selectedCustomerId,
        userId,
      },
    });
  };

  const getParsedUserInfo = async () => {
    const response = await fetchUser();

    const data = response.data;

    let leakFinderSettings: LeakFinderSettingsInput | null = null;

    try {
      if (data?.getUserById.userSettings) {
        const settings = JSON.parse(data?.getUserById.userSettings);

        leakFinderSettings = {
          defaultCameraFov:
            +settings?.defaultCameraFov ??
            userSettings?.defaultCameraFov ??
            DEFAULT_CAMERA_FOV,
          defaultWindspeedUnit:
            settings?.defaultWindspeedUnit ??
            userSettings?.defaultWindspeedUnit ??
            DEFAULT_WINDSPEED_UNIT,
        };
      }
    } catch (error) {
      console.error(error);
    }

    return {
      cameraFov: leakFinderSettings?.defaultCameraFov ?? DEFAULT_CAMERA_FOV,
      windspeedUnit:
        leakFinderSettings?.defaultWindspeedUnit ?? DEFAULT_WINDSPEED_UNIT,
      userId: data?.getUserById.id as string,
      userRole: data?.getUserById.user_role as string,
    };
  };

  return { fetchUser, getParsedUserInfo, loading };
};
