import { useEffect, useMemo, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetCustomerHealthQuery,
  GetCustomerHealthQueryVariables,
} from "../../../API";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { GET_CUSTOMER_HEALTH } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";

interface IHealthStatus {
  status: boolean;
  uptime: string;
}

interface IParsedNodeHealthCheckStatus extends IHealthStatus {
  nodeId: string;
  nodeName: string;
}

interface IParsedDeviceHealthCheckStatus extends IHealthStatus {
  deviceId: string;
  deviceName: string;
}

interface IParsedServiceHealthCheckStatus extends IHealthStatus {
  serviceType: string;
  serviceId: string;
}

export interface IParsedDeploymentHealthRow {
  locationId: string;
  locationName: string;
  nodes: IParsedNodeHealthCheckStatus[];
  devices: IParsedDeviceHealthCheckStatus[];
  services: IParsedServiceHealthCheckStatus[];
}

interface IUseGetCustomerHealth {
  rows?: IParsedDeploymentHealthRow[];
  loading: boolean;
  isHealthDataLoaded: boolean;
}

export const useGetCustomerHealth = (): IUseGetCustomerHealth => {
  const selectedCustomerId = useCustomerIdGuard();

  const [isHealthDataLoaded, setIsHealthDataLoaded] = useState(false);

  useEffect((): void => {
    if (selectedCustomerId) {
      getCustomerHealth({
        variables: {
          customerId: selectedCustomerId,
        },
      });
    }
  }, [selectedCustomerId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();

    errorNotification();
  };

  const [getCustomerHealth, { data, loading }] = useLazyQuery<
    GetCustomerHealthQuery,
    GetCustomerHealthQueryVariables
  >(GET_CUSTOMER_HEALTH, {
    fetchPolicy: "network-only",
    refetchWritePolicy: "merge",
    onError: handleRequestError,
  });

  const rows = useMemo(() => {
    if (!data?.getCustomerHealth) return [];

    try {
      const { getCustomerHealth } = data;

      setIsHealthDataLoaded(true);

      return JSON.parse(getCustomerHealth ?? "[]");
    } catch (error) {
      console.error(error);

      return [];
    }
  }, [data?.getCustomerHealth]);

  return { rows, loading, isHealthDataLoaded };
};
