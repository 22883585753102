import { FC, useEffect, useRef } from "react";

import Hls from "hls.js";

import { videoPlayerVariable } from "../../../../../../common/variables/videoPlayer";

interface LiveStreamPlayerProps {
  streamUrl: string;
  setDialogStyles?: boolean;
}

const LiveStreamPlayer: FC<LiveStreamPlayerProps> = ({
  streamUrl,
  setDialogStyles,
}): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect((): any => {
    let hls: Hls;

    if (videoRef.current) {
      if (Hls.isSupported()) {
        hls = new Hls({
          liveSyncDurationCount: 3,
          maxMaxBufferLength: 30,
        });

        hls.loadSource(streamUrl);

        hls.attachMedia(videoRef.current);

        hls.on(Hls.Events.MANIFEST_PARSED, (): void => {
          if (videoRef.current) videoRef.current.playbackRate = 1.1;

          videoRef.current
            ?.play()
            .then((): void => {
              videoPlayerVariable(true);
            })
            .catch((error: any): void =>
              console.error("Error playing video:", error)
            );
        });
      } else if (
        videoRef.current.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoRef.current.src = streamUrl;

        videoRef.current.addEventListener("loadedmetadata", (): void => {
          videoRef.current
            ?.play()
            .catch((error: any): void =>
              console.error("Error playing video:", error)
            );
        });
      }
    }

    return (): any => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [streamUrl]);

  return (
    <video
      ref={videoRef}
      autoPlay
      controls
      muted
      style={{
        width: "100%",
        height: "100%",
        borderRadius: setDialogStyles ? "0px" : "8px",
        objectFit: "fill",
      }}
    >
      Your browser does not support the video tag.
    </video>
  );
};

export default LiveStreamPlayer;
