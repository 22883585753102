import React, { Fragment, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { Annotation, AnnotationInput, AnnotationType } from "../../../API";
import WarningBox from "../../../common/components/box/WarningBox";
import CanvasContainer, {
  CanvasFigure,
} from "../../../common/components/canvas/CanvasContainer";
import useCreateImageLayer from "../../../common/components/canvas/useCreateImageLayer";
import { PointsFormat } from "../../../common/components/canvas/utils";
import CloseDialogItemIcon from "../../../common/components/icons/CloseCrossIcon";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useGetServiceById } from "../../model-manager/hooks/useGetServiceById";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";
import AnnotationList from "../components/AnnotationsList";
import ToggleButtonGroupComponent from "../components/ToggleButtonGroupComponent";
import { useCreateAnnotation } from "../hooks/useCreateAnnotation";
import { useDeleteAllAnnotations } from "../hooks/useDeleteAllAnnotations";
import { IModel } from "../hooks/useDeviceTableRows";
import { useUpdateAnnotation } from "../hooks/useUpdateAnnotation";
import AnnotationDialog from "./AnnotationDialog";
import AnnotationTableHeader from "./AnnotationTableHeader";
import DialogActions from "./DialogActions";

export type AnnotationTableItem = {
  type: AnnotationType;
  figure: CanvasFigure;
  title: string;
  additionTitle: string;
  correspondingAnnotations: Annotation[];
};

type AnnotationContainerProps = {
  annotationsData: Annotation[];
  serviceId: string;
  serviceType: ServiceTypeEnum;
  setKeyframeLoaded: (value: boolean) => void;
  modelDetails?: IModel;
  keyFrame: string;
  isKeyframeLoaded: boolean;
  deviceId: string;
  nodeId: string;
  zoneId?: string;
};

type FormValues = {
  annotationName: string;
  tankHeight: number;
  bottomHidden: number;
  tankAnnotation: boolean;
  hasTankOutlineAnnotation: boolean;
  tankNumber: number;
};

const AnnotationContainer: React.FC<AnnotationContainerProps> = ({
  annotationsData,
  zoneId,
  serviceId,
  keyFrame,
  nodeId,
  deviceId,
  serviceType,
  isKeyframeLoaded,
  setKeyframeLoaded,
}): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [coordinates, setCoordinates] = useState<PointsFormat>([]);
  const [annotationType, setAnnotationType] = useState<AnnotationType>();
  const [
    isDeleteAllAnnotationsDialogOpen,
    setIsDeleteAllAnnotationsDialogOpen,
  ] = useState(false);

  let firstElemAnnotationType =
    annotationsData.length > 0
      ? annotationsData[0].annotationType
      : AnnotationType.EXCLUSION_AREA;

  if (
    annotationsData.length === 0 &&
    serviceType === ServiceTypeEnum.LIQUID_LEAK
  ) {
    firstElemAnnotationType = AnnotationType.INCLUSION_AREA;
  }

  const [isToggleSetToInclusion, setIsToggleToInclusion] = useState(
    (firstElemAnnotationType as AnnotationType) ===
      AnnotationType.INCLUSION_AREA ||
      (firstElemAnnotationType as AnnotationType) ===
        AnnotationType.INCLUSION_AREA_STATIC
      ? ServiceTypeEnum.LIQUID_LEAK
      : ServiceTypeEnum.LEAK
  );

  const [currentToggleValue, setCurrentToggleValue] = useState(
    isToggleSetToInclusion
  );

  const [editMode, setEditMode] = useState({
    annotationId: "",
    editing: false,
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const { createAnnotation, loading: createAnnotationLoading } =
    useCreateAnnotation();
  const { updateAnnotation, loading: updateAnnotationLoading } =
    useUpdateAnnotation();
  const { fetchService, data: serviceData } = useGetServiceById();
  const { deleteAllAnnotations } = useDeleteAllAnnotations();

  const { syncShadowsForNode } = useSyncShadow();
  const customerId = useCustomerIdGuard();

  const { image, imageRef, scaleX, scaleY, skaleFactor } = useCreateImageLayer(
    keyFrame,
    setKeyframeLoaded
  );

  const isLoading = createAnnotationLoading || updateAnnotationLoading;

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: ServiceTypeEnum | null
  ) => {
    if (newValue !== null) {
      if (annotationsData.length === 0) {
        setIsToggleToInclusion(newValue);
      } else {
        setCurrentToggleValue(newValue);

        setIsDeleteAllAnnotationsDialogOpen(true);
      }
    }
  };

  useEffect(() => {
    // fetch service so we can use associated nodeId when creating annotation
    let correctFormatServiceId = serviceId;

    // 4/23 if the serviceId has a C# in it, remove it because DDB service items dont have it in their sk
    if (serviceId.startsWith("C#")) {
      correctFormatServiceId = "S#" + serviceId.split("S#")[1];
    }

    fetchService({
      customerId,
      serviceId: correctFormatServiceId,
    });
  }, [serviceId]);

  const onSetPoints = (newPoints: PointsFormat): void =>
    setCoordinates(newPoints);

  const openCreateAnnDialog = (annotationType: AnnotationType): void => {
    reset();

    setIsDialogOpen(true);

    setAnnotationType(annotationType);
  };

  const onCloseDialogHandler = (): void => {
    reset();

    setEditMode({ annotationId: "", editing: false });

    setCoordinates([]);

    setIsDialogOpen(false);
  };

  const handleOpenEditDialog = (item: Annotation) => {
    const parsedPolygon = JSON.parse(item?.polygon ?? "");

    setValue("annotationName", item.name);

    setValue("tankHeight", item.tankHeight ?? 0);

    setValue("bottomHidden", item.bottomHidden ?? 0);

    setValue("tankNumber", item.tankNumber ?? 0);

    setValue("tankAnnotation", item.annotateTanks ?? true);

    setCoordinates(parsedPolygon.annotations as PointsFormat);

    setAnnotationType(item.annotationType);

    setIsDialogOpen(true);

    setEditMode({ annotationId: item.id ?? "", editing: true });
  };

  const handleDeleteAllAnnotations = async () => {
    const serviceId =
      annotationsData.length > 0 ? annotationsData[0].serviceId : "";

    await deleteAllAnnotations(serviceId);

    successNotification("All Annotations Deleted");

    syncShadowsForNode(nodeId);

    setIsDeleteAllAnnotationsDialogOpen(false);

    setIsToggleToInclusion(currentToggleValue);
  };

  const convertFloatsToIntegers = (arr: any) => {
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        arr[i][j] = Math.round(arr[i][j]); // Convert float to integer using Math.round()
      }
    }

    return arr;
  };

  const saveAnnotation = (
    item: AnnotationTableItem,
    formData: FormValues
  ): void => {
    const isGateGuard = annotationType === AnnotationType.SEARCH_AREA;
    const lessThan3Points = coordinates.length < 3;

    if (lessThan3Points) {
      errorNotification("Please dot at least three points");

      return;
    }

    const correctName = isGateGuard ? "Search area" : formData.annotationName;

    let correctFormatDeviceId = deviceId;

    // add the prefix for customerId if its not there
    if (correctFormatDeviceId.startsWith("DE#"))
      correctFormatDeviceId = customerId + "#" + deviceId;

    const input: AnnotationInput = {
      polygon: JSON.stringify({
        // TODO: convert all values inside this data type to integers via rounding
        annotations: convertFloatsToIntegers(coordinates),
      }),
      id: editMode.annotationId,
      zoneId,
      nodeId: serviceData?.nodeId ?? "",
      serviceId,
      annotationType: annotationType as AnnotationType,
      // TODO: temporary fix before we make the change of adding C# to deviceId upon device creation
      deviceId: correctFormatDeviceId,
      // TLM into configuration map

      annotateTanks: true, // hardcode as true, until we enable allowing user to input
      name: correctName,
      tankNumber: formData.tankNumber
        ? Math.round(parseFloat(String(formData.tankNumber)))
        : 5, // need to ask from user
      tankHeight: formData.tankHeight
        ? Math.round(parseFloat(String(formData.tankHeight)))
        : 188,
      bottomHidden: formData.bottomHidden
        ? Math.round(parseFloat(String(formData.bottomHidden)))
        : 0, // need to ask from user, default to 0
    };

    if (annotationType !== AnnotationType.TANK_LEVEL) {
      delete input.tankNumber;

      delete input.annotateTanks;

      delete input.tankHeight;

      delete input.bottomHidden;
    }

    const fetchHelper = () => {
      if (input.annotationType !== AnnotationType.TANK_LEVEL) {
        delete input.tankHeight;

        delete input.bottomHidden;
      }

      if (editMode.editing) {
        return updateAnnotation(input);
      } else {
        delete input.id;

        return createAnnotation(input);
      }
    };

    const successMessage = editMode.editing
      ? "Changes are saved"
      : `${item.additionTitle} is added`;

    fetchHelper()
      .then((response): void => {
        if (response?.data) {
          successNotification(successMessage);

          setIsDialogOpen(false);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();

        console.error(error);
      })
      .finally((): void => {
        onCloseDialogHandler();

        syncShadowsForNode(nodeId);
      });
  };

  const deleteEditableExclusion = useMemo(
    () =>
      annotationsData.filter(
        annotation => annotation.id !== editMode.annotationId
      ),
    [annotationsData, editMode]
  );

  // CONTROL PANEL OF WHICH ANNOTATIONS SHOW
  const annotationTables: AnnotationTableItem[] = useMemo(() => {
    const result: AnnotationTableItem[] = [];

    const typeOfInclusionArea = zoneId
      ? AnnotationType.INCLUSION_AREA
      : AnnotationType.INCLUSION_AREA_STATIC;

    const typeOfExclusionArea = zoneId
      ? AnnotationType.EXCLUSION_AREA
      : AnnotationType.EXCLUSION_AREA_STATIC;

    const typeOfCombustionArea = zoneId
      ? AnnotationType.COMBUSTION_AREA
      : AnnotationType.COMBUSTION_AREA_STATIC;

    switch (true) {
      case serviceType === ServiceTypeEnum.HARD_HAT:
      case serviceType === ServiceTypeEnum.FIRE_SMOKE:
        result.push({
          type: AnnotationType.EXCLUSION_AREA_STATIC,
          figure: CanvasFigure.POLYGON,
          title: "Exclusion areas",
          additionTitle: "Exclusion area",
          correspondingAnnotations: annotationsData.filter(
            e => e?.annotationType === AnnotationType.EXCLUSION_AREA_STATIC
          ),
        });
        break;

      case serviceType === ServiceTypeEnum.LIQUID_LEAK:
        if (isToggleSetToInclusion === ServiceTypeEnum.LEAK) {
          result.push({
            type: typeOfExclusionArea,
            figure: CanvasFigure.POLYGON,
            title: "Exclusion areas",
            additionTitle: "Exclusion area",
            correspondingAnnotations: annotationsData.filter(
              e => e?.annotationType === typeOfExclusionArea
            ),
          });
        } else {
          result.push({
            type: typeOfInclusionArea,
            figure: CanvasFigure.POLYGON,
            title: "Inclusion areas",
            additionTitle: "Inclusion area",
            correspondingAnnotations: annotationsData.filter(
              e => e?.annotationType === typeOfInclusionArea
            ),
          });
        }

        break;

      case serviceType === ServiceTypeEnum.LEAK:
        if (isToggleSetToInclusion === ServiceTypeEnum.LIQUID_LEAK) {
          result.push({
            type: typeOfInclusionArea,
            figure: CanvasFigure.POLYGON,
            title: "Inclusion areas",
            additionTitle: "Inclusion area",
            correspondingAnnotations: annotationsData.filter(
              e => e?.annotationType === typeOfInclusionArea
            ),
          });
        } else {
          result.push({
            type: typeOfExclusionArea,
            figure: CanvasFigure.POLYGON,
            title: "Exclusion areas",
            additionTitle: "Exclusion area",
            correspondingAnnotations: annotationsData.filter(
              e => e?.annotationType === typeOfExclusionArea
            ),
          });

          result.push({
            type: typeOfCombustionArea,
            figure: CanvasFigure.POLYGON,
            title: "Combustion area",
            additionTitle: "Combustion area",
            correspondingAnnotations: annotationsData.filter(
              e => e?.annotationType === typeOfCombustionArea
            ),
          });
        }

        break;

      case serviceType === ServiceTypeEnum.GATE_GUARD:
        result.push({
          type: AnnotationType.SEARCH_AREA,
          figure: CanvasFigure.RECTANGLE,
          title: "Search area",
          additionTitle: "Search area",
          correspondingAnnotations: annotationsData.filter(
            e => e?.annotationType === AnnotationType.SEARCH_AREA
          ),
        });
        break;

      case serviceType === ServiceTypeEnum.TANK_LEVEL:
        result.push({
          type: AnnotationType.TANK_LEVEL,
          figure: CanvasFigure.RECTANGLE,
          title: "Tanks",
          additionTitle: "Tank",
          correspondingAnnotations: annotationsData.filter(
            e => e?.annotationType === AnnotationType.TANK_LEVEL
          ),
        });
        break;

      default:
        break;
    }

    return result;
  }, [annotationsData, isToggleSetToInclusion]);

  return (
    <Box>
      {annotationTables.map((item, index) => {
        const renderOnlyNeededDialog = annotationType === item.type;
        const typeName = item.additionTitle.toLocaleLowerCase();
        const isFirstElem = index === 0;

        const addOrEdit = editMode.editing ? "Edit" : "Add";
        const confirmText = editMode.editing
          ? "Save changes"
          : `${addOrEdit} ${typeName}`;

        const promptText =
          item.figure === CanvasFigure.RECTANGLE
            ? `Move the green highlighted rectangle on the image to highlight the place where the ${typeName} is`
            : `Place at least three dots on the image to highlight the ${typeName}. The
                    shape is completed after you click the first place dot at
                    the end`;

        const disableButton =
          item.type === AnnotationType.EQUIPMENT_TAG ||
          (item.type === AnnotationType.EXCLUSION_AREA &&
            serviceType === ServiceTypeEnum.HARD_HAT);

        return (
          <Fragment key={item.type}>
            <Divider
              sx={{
                margin: `${isFirstElem ? "1em 0 1.5em 0" : "1.5em 0"}`,
                opacity: "0.5",
              }}
            />

            {!isKeyframeLoaded && isFirstElem && (
              <WarningBox>
                Please wait till the system retrieves key frames and then
                you&apos;ll be able to manage areas
              </WarningBox>
            )}

            {(serviceType === ServiceTypeEnum.LEAK ||
              serviceType === ServiceTypeEnum.LIQUID_LEAK) &&
              isFirstElem && (
                <Stack direction={"column"} spacing={2}>
                  <WarningBox>
                    Only one area type can be added. If you switch to another
                    type below, added areas to the previous type will be deleted
                  </WarningBox>
                  <Stack direction={"row"}>
                    <ToggleButtonGroupComponent
                      value={isToggleSetToInclusion}
                      onChange={handleChange}
                      ServiceTypeEnum={ServiceTypeEnum}
                    />
                    <Dialog
                      open={isDeleteAllAnnotationsDialogOpen}
                      onClose={() => setIsDeleteAllAnnotationsDialogOpen(false)}
                    >
                      <Paper
                        elevation={0}
                        sx={{ margin: 4, marginLeft: 8, marginRight: 8 }}
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2.5}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0.5}
                          >
                            <Typography variant="h4">
                              Switch to another area type
                            </Typography>
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={0.5}
                            >
                              <Typography variant="caption">
                                If you switch to another area type, added areas
                                to the previous type
                              </Typography>
                              <Typography variant="caption">
                                will be deleted
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <Button
                              color="inherit"
                              variant="outlined"
                              onClick={() =>
                                setIsDeleteAllAnnotationsDialogOpen(false)
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleDeleteAllAnnotations}
                            >
                              Switch to another area type
                            </Button>
                          </Stack>
                        </Stack>
                      </Paper>
                    </Dialog>
                  </Stack>
                </Stack>
              )}

            <AnnotationTableHeader
              openCreateAnnDialog={() => openCreateAnnDialog(item.type)}
              disableButton={disableButton}
              isKeyframeLoaded={isKeyframeLoaded}
              item={item}
            />

            {!!item.correspondingAnnotations.length && isKeyframeLoaded && (
              <AnnotationList
                nodeId={nodeId}
                annotationsType={item.additionTitle}
                annotationsData={item.correspondingAnnotations}
                handleOpenDialog={handleOpenEditDialog}
              />
            )}

            {renderOnlyNeededDialog && (
              <AnnotationDialog
                isDialogOpen={isDialogOpen}
                boardChildren={
                  <CanvasContainer
                    legendItems={annotationTables}
                    typeOfAnnotation={item.type}
                    figureType={item.figure}
                    annotationsData={deleteEditableExclusion}
                    points={coordinates}
                    editMode={editMode.editing}
                    onSetPoints={onSetPoints}
                    image={image}
                    imageRef={imageRef}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    skaleFactor={skaleFactor}
                  />
                }
                dialogActions={
                  <form
                    onSubmit={handleSubmit((formData: FormValues) => {
                      saveAnnotation(item, formData);
                    })}
                  >
                    <DialogActions
                      loading={isLoading}
                      onCloseDialogHandler={onCloseDialogHandler}
                      cancelText="Cancel"
                      confirmText={confirmText}
                    />
                  </form>
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-star",
                    justifyContent: "space-between",
                    marginBottom: "1em",
                  }}
                >
                  <Typography variant="h4">
                    {`${addOrEdit} ${typeName}`}
                  </Typography>

                  <CloseDialogItemIcon onClose={onCloseDialogHandler} />
                </Box>

                <Box sx={{ marginBottom: "0.5em" }}>
                  <WarningBox>{promptText}</WarningBox>
                </Box>

                <Controller
                  name="annotationName"
                  control={control}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value || ""}
                      margin="dense"
                      sx={{
                        height: "68px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                        },
                      }}
                      label={item.additionTitle + " name"}
                      fullWidth
                      error={!!errors.annotationName}
                      helperText={
                        errors.annotationName
                          ? errors.annotationName.message
                          : ""
                      }
                    />
                  )}
                />

                {item.type === AnnotationType.TANK_LEVEL && (
                  <>
                    <Controller
                      name="tankHeight"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value || ""}
                          type="number"
                          margin="dense"
                          sx={{
                            height: "68px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                            },
                            "& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
                              {
                                WebkitAppearance: "none",
                                margin: 0,
                              },
                            "& input[type='number']": {
                              MozAppearance: "textfield",
                            },
                          }}
                          label="Tank height (inches)"
                          fullWidth
                          error={!!errors.tankHeight}
                          helperText={
                            errors.tankHeight ? errors.tankHeight.message : ""
                          }
                        />
                      )}
                    />

                    <Box sx={{ display: "flex", gap: "1em" }}>
                      <Controller
                        name="bottomHidden"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value || ""}
                            margin="dense"
                            type="number"
                            sx={{
                              height: "68px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                              },
                              "& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
                                {
                                  WebkitAppearance: "none",
                                  margin: 0,
                                },
                              "& input[type='number']": {
                                MozAppearance: "textfield",
                              },
                            }}
                            label="Tank bottom hidden"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="tankNumber"
                        control={control}
                        rules={{
                          required: "This field is required",
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value || ""}
                            type="number"
                            margin="dense"
                            sx={{
                              height: "68px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                              },
                              "& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
                                {
                                  WebkitAppearance: "none",
                                  margin: 0,
                                },
                              "& input[type='number']": {
                                MozAppearance: "textfield",
                              },
                            }}
                            label="Tank number"
                            fullWidth
                            error={!!errors.tankNumber}
                            helperText={
                              errors.tankNumber ? errors.tankNumber.message : ""
                            }
                          />
                        )}
                      />
                    </Box>
                  </>
                )}
              </AnnotationDialog>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default AnnotationContainer;
