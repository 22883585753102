import { Box } from "@mui/material";

import { useAuthenticatedUser } from "../../../../../../../common/hooks/useAuthenticatedUser";
import { RoleEnum } from "../../../../../../../common/models/enums";
import PTZoneListTable from "./PTZoneListTable";
import { usePTGetZonesByDeviceTableColumns } from "./hooks/usePTGetZonesByDeviceTableColumns";
import { usePTGetZonesByDeviceTableRow } from "./hooks/usePTGetZonesByDeviceTableRow";

export const ADMIN_ACCESS = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.CUSTOMER_ADMIN,
];

export const NON_ADMIN_ACCESS = [
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  RoleEnum.PARTNER_USER,
  RoleEnum.CUSTOMER_OPS_TEAM,
  RoleEnum.CUSTOMER_HSE,
  RoleEnum.CUSTOMER_SECURITY,
];

interface IPTZoneListTableContainerProps {
  currentDeviceId: string;
  nodeId: string;
}

const PTZoneListTableContainer = ({
  currentDeviceId,
  nodeId,
}: IPTZoneListTableContainerProps): JSX.Element | null => {
  const { role } = useAuthenticatedUser();

  const { rows, loading } = usePTGetZonesByDeviceTableRow(currentDeviceId);
  const { columns, rowModesModel, setRowModesModelChange } =
    usePTGetZonesByDeviceTableColumns();

  const hasAdminAccess = ADMIN_ACCESS.includes(role);
  const hasNonAdminAccess = NON_ADMIN_ACCESS.includes(role);

  if (!hasAdminAccess && !hasNonAdminAccess) {
    return <Box padding="1.5em">You do not have access</Box>;
  }

  const nonAdminProps = {
    rows,
    columns,
    editMode: true,
    loading,
    nodeId,
  };

  const adminProps = {
    ...nonAdminProps,
    editMode: true,
    rowModesModel,
    setRowModesModelChange,
    nodeId,
  };

  const props = hasAdminAccess ? adminProps : nonAdminProps;

  return <PTZoneListTable {...props} />;
};

export default PTZoneListTableContainer;
