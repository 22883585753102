import { useState } from "react";

import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import CircleArrowUp from "../icons/CircleArrowUp";
import CircleArrowLeft from "../icons/CircleArrowLeft";
import CircleArrowRight from "../icons/CircleArrowRight";
import CircleArrowDown from "../icons/CircleArrowDown";
import {
  ICreateZoneValidationState,
  useZoneFormValidation,
} from "../../../pages/devices/zones-setup-page/hooks/useZoneFormValidation";

type ControlTableProps = {
  pan: string;
  tilt: string;
  validation?: ICreateZoneValidationState;
  showValidationAlert?: boolean;
  movePtUp: () => void;
  movePtDown: () => void;
  movePtLeft: () => void;
  movePtRight: () => void;
  handleGoToPt?: (pan: any, tilt: any) => void;
  handlePanInputChange: (pan?: string) => void;
  handleTiltInputChange: (tilt?: string) => void;
};

const ControlTable: React.FC<ControlTableProps> = ({
  pan,
  tilt,
  validation,
  showValidationAlert,
  handleGoToPt,
  handlePanInputChange,
  handleTiltInputChange,
  movePtDown,
  movePtLeft,
  movePtRight,
  movePtUp,
}): JSX.Element => {
  const { validatePanTilt } = useZoneFormValidation();

  const [disabled, setDisabled] = useState(false);

  const goToPtHandler = (): void => {
    if (!handleGoToPt) return;

    const isValid = validatePanTilt(pan, tilt);

    handleGoToPt(pan, tilt);

    if (isValid) {
      setDisabled(true);

      setTimeout((): void => {
        setDisabled(false);
      }, 10000);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Table size="small" sx={{ width: "240px" }}>
          <TableBody>
            <TableRow
              sx={{
                "& td": {
                  border: 0,
                  padding: 0,
                },
                "& .small": {
                  width: "32px",
                  height: "32px",
                },
                "& .pan": {
                  paddingLeft: "1.5em",
                },
                "& .tilt": {
                  paddingLeft: "0.5em",
                },
              }}
            >
              <TableCell className="small"></TableCell>
              <TableCell className="small">
                <CircleArrowUp
                  width={32}
                  height={32}
                  fontSize="large"
                  onClick={movePtUp}
                />
              </TableCell>
              <TableCell className="small"></TableCell>
              <TableCell rowSpan={3} className="pan tilt">
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "10px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="inputLabel"
                    sx={{ position: "absolute" }}
                    component="span"
                    color="otherTextTertiary.main"
                  >
                    Pan
                  </Typography>
                  <TextField
                    type="text"
                    error={validation?.pan.hasError}
                    helperText={validation?.pan.errorMessage}
                    InputProps={{ sx: { borderRadius: "8px" } }}
                    sx={{
                      minWidth: "72px",
                      maxWidth: "76px",
                      position: "relative",
                      left: "30px",
                      "& input": {
                        borderRadius: "8px",
                        height: "18px",
                        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                          {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                      },
                    }}
                    id="pan"
                    size="small"
                    variant="outlined"
                    margin="dense"
                    value={pan}
                    onChange={(e): void => handlePanInputChange(e.target.value)}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "10px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="inputLabel"
                    sx={{ position: "absolute" }}
                    component="span"
                    color="otherTextTertiary.main"
                  >
                    Tilt
                  </Typography>
                  <TextField
                    type="text"
                    error={validation?.tilt.hasError}
                    helperText={validation?.tilt.errorMessage}
                    InputProps={{ sx: { borderRadius: "8px" } }}
                    sx={{
                      minWidth: "72px",
                      maxWidth: "76px",
                      position: "relative",
                      left: "30px",
                      "& input": {
                        height: "18px",
                        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                          {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                      },
                    }}
                    id="tilt"
                    size="small"
                    variant="outlined"
                    margin="dense"
                    value={tilt}
                    onChange={(e): void =>
                      handleTiltInputChange(e.target.value)
                    }
                  />
                </Box>
              </TableCell>
              <TableCell rowSpan={3}></TableCell>
            </TableRow>

            <TableRow
              sx={{
                "& td": {
                  border: 0,
                  padding: 0,
                },
                "& .small": {
                  width: "32px",
                  height: "32px",
                },
              }}
            >
              <TableCell className="small">
                <CircleArrowLeft
                  width={32}
                  height={32}
                  fontSize="large"
                  onClick={movePtLeft}
                />
              </TableCell>
              <TableCell className="small"></TableCell>
              <TableCell className="small">
                <CircleArrowRight
                  width={32}
                  height={32}
                  fontSize="large"
                  onClick={movePtRight}
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow
              sx={{
                "& td": {
                  border: 0,
                  padding: 0,
                },
                "& .small": {
                  width: "32px",
                  height: "32px",
                },
              }}
            >
              <TableCell className="small"></TableCell>
              <TableCell className="small">
                <CircleArrowDown
                  width={32}
                  height={32}
                  fontSize="large"
                  onClick={movePtDown}
                />
              </TableCell>
              <TableCell className="small"></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="text"
            disabled={!pan || !tilt || disabled}
            onClick={goToPtHandler}
            size="small"
          >
            Apply
          </Button>
        </Box>
      </Box>
      {showValidationAlert && (
        <>
          <Box
            sx={{
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            {validation?.panValue.hasError && (
              <Alert severity="error">
                {validation?.panValue.errorMessage}
              </Alert>
            )}
          </Box>
          <Box>
            {validation?.tiltValue.hasError && (
              <Alert severity="error">
                {validation?.tiltValue.errorMessage}
              </Alert>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default ControlTable;
