import { gql } from "@apollo/client";

import { User } from "../../API";
import client from "../../configs/apolloClient";

const useUserFromCache = () => {
  const getCachedUser = (userId: string): User | null => {
    const normalizedId =
      client.cache.identify({
        id: userId,
        __typename: "User",
      }) ?? "";

    const user = client.cache.readFragment<User>({
      id: normalizedId,
      fragment: gql`
        fragment UserFragment on User {
          customer_id
          email
          firstName
          id
          lastName
          userSettings
          user_group
          user_role
        }
      `,
    });

    return user;
  };

  return { getCachedUser };
};

export default useUserFromCache;
