import AWS, { AWSError } from "aws-sdk";
import { DataEndpoint } from "aws-sdk/clients/kinesisvideo";

import aws from "../../aws-exports.json";
import { getJwtToken } from "./getJwtToken";

export class kinesishelper {
  public static getUrl = async (streamName: string) => {
    if (!streamName) {
      throw new Error("Stream name is required");
    }

    const jwt = await getJwtToken();

    AWS.config.region = aws.aws_user_files_s3_bucket_region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: aws.aws_cognito_identity_pool_id,
      Logins: {
        ["cognito-idp.us-east-1.amazonaws.com/" + aws.aws_user_pools_id]: jwt,
      },
    });

    const kinesisVideo = new AWS.KinesisVideo();

    try {
      // get a data endpoint for the kinesisVideo stream
      console.log(
        "Getting data endpoint for Kinesis for STREAMNAME: ",
        streamName
      );
      const EP = await kinesisVideo
        .getDataEndpoint({
          StreamName: streamName,
          APIName: "GET_HLS_STREAMING_SESSION_URL",
        })
        .promise();

      return EP.DataEndpoint;
    } catch (error) {
      throw new Error(
        `Could not get HLS from Kinesis: ${(error as AWSError).message}`
      );
    }
  };

  public static getHLSStreamingSessionURL = async (
    streamName: string,
    dataEndpoint: DataEndpoint
  ) => {
    if (!streamName || !dataEndpoint) {
      return;
    }

    try {
      const kinesisVideoArchivedContent = new AWS.KinesisVideoArchivedMedia({
        endpoint: new AWS.Endpoint(dataEndpoint),
      });

      // get an HLS Streaming Session URL
      const response = await kinesisVideoArchivedContent
        .getHLSStreamingSessionURL({
          StreamName: streamName,
          PlaybackMode: "LIVE",
          DiscontinuityMode: "ALWAYS",
          ContainerFormat: "FRAGMENTED_MP4",
          MaxMediaPlaylistFragmentResults: 3,
          Expires: 3600,
        })
        .promise();

      return response.HLSStreamingSessionURL;
    } catch (error) {
      throw new Error(
        `Could not get HLSStreamingSessionURL: ${(error as AWSError).message}`
      );
    }
  };
}
