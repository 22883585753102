import { FC, useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";

import CircularLoading from "../../../common/components/progress/CircularLoading";
import {
  S3BucketKeys,
  S3BucketKeyType,
  S3Helper,
} from "../../../common/utils/s3helper";
import NoMedia from "./errors/NoMedia";

interface IVideoViewerProps {
  s3Key: string;
  s3BucketKey: S3BucketKeyType;
}

const VideoViewer: FC<IVideoViewerProps> = ({ s3Key, s3BucketKey }) => {
  const [loading, setLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  const videoRef = useRef<HTMLVideoElement | null>();

  useEffect((): void => {
    if (s3Key) {
      setLoading(true);

      const isLeakFinderUrl = S3BucketKeys.LEAK_FINDER === s3BucketKey;

      const getMediaObject = isLeakFinderUrl
        ? S3Helper.getSignedUrl(s3Key, "getObject")
        : S3Helper.getObject(s3Key);

      getMediaObject
        .then((result: string): void => {
          setMediaUrl(result);
        })
        .catch((error: Error): void => console.log("S3Video", error.message))
        .finally((): void => setLoading(false));
    }
  }, [s3Key]);

  const handleSetPlaybackRate = (): void => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2; // Set the playback rate to 2x

      videoRef.current.muted = true; // Mute the video; this is required for autoplay

      // Attempt to autoplay the video: https://developer.chrome.com/blog/autoplay/#new_behaviors
      const promise = videoRef.current.play();

      if (promise !== undefined) {
        promise
          .then(() => {
            console.info("Autoplay started");
          })
          .catch(() => {
            console.warn("Autoplay was prevented.");
          });
      }
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularLoading />
      </Box>
    );
  }

  return (
    <>
      {!loading && !s3Key && <NoMedia />}
      {!loading && s3Key && !mediaUrl && <NoMedia />}
      {!loading && mediaUrl && (
        <CardMedia
          sx={{
            height: "100%",
            maxHeight: "100vh",
          }}
          id="video"
          component="video"
          controls
          src={mediaUrl}
          autoPlay
          ref={(el: HTMLVideoElement | null): void => {
            if (el) {
              videoRef.current = el; // Assign the video element to the ref
            }
          }}
          onLoadedData={handleSetPlaybackRate}
        />
      )}
    </>
  );
};

export default VideoViewer;
