import { makeVar, useReactiveVar } from "@apollo/client";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";

export const selectedDeploymentHealthLocationVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedDeploymentHealthLocation = () =>
  useReactiveVar(selectedDeploymentHealthLocationVariable);

export const filterByOfflineVariable = makeVar<boolean>(false);

export const useFilterByOffline = () => useReactiveVar(filterByOfflineVariable);

export const selectedDeploymentHealthTagLocationsVariable = makeVar<
  AutocompleteOptionType[] | null
>(null);

export const useSelectedDeploymentHealthTagLocations = () =>
  useReactiveVar(selectedDeploymentHealthTagLocationsVariable);
