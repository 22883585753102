import { useEffect, useMemo } from "react";

import {
  selectedDeploymentHealthLocationVariable,
  useFilterByOffline,
  useSelectedDeploymentHealthLocation,
  useSelectedDeploymentHealthTagLocations,
} from "../variables/deploymentHealth";

import {
  IParsedDeploymentHealthRow,
  useGetCustomerHealth,
} from "./useGetCustomerHealth";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";

export interface IDeploymentHealthTableRow extends IParsedDeploymentHealthRow {
  locationStatus: boolean;
  nodeStatus: boolean;
  deviceStatus: boolean;
  serviceStatus: boolean;
}

interface IUseDeploymentHealthTableRows {
  data?: IDeploymentHealthTableRow[];
  loading: boolean;
  isHealthDataLoaded: boolean;
}

export const useDeploymentHealthTableRows =
  (): IUseDeploymentHealthTableRows => {
    const { rows, loading, isHealthDataLoaded } = useGetCustomerHealth();
    const locationVariable = useSelectedDeploymentHealthLocation();
    const tagLocationsVariable = useSelectedDeploymentHealthTagLocations();
    const offlineStatus = useFilterByOffline();

    function removeGasLeakType(serviceType: string): string {
      return serviceType.replace(/Gas /g, "");
    }

    useEffect(() => {
      return () => {
        selectedDeploymentHealthLocationVariable(null);
      };
    }, []);

    const data = useMemo(() => {
      if (!rows) return [];

      const mappedData = rows.map((row: IParsedDeploymentHealthRow) => {
        const nodeStatus = row.nodes.every(node => node.status);
        const deviceStatus = row.devices.every(device => device.status);
        const serviceStatus = row.services.every(service => service.status);

        const locationStatus = nodeStatus && deviceStatus && serviceStatus;

        const updatedServices = row.services.map(service => ({
          ...service,
          serviceType: service.serviceType.includes("Gas ")
            ? removeGasLeakType(service.serviceType)
            : service.serviceType,
        }));

        return {
          ...row,
          services: updatedServices,
          locationStatus,
          nodeStatus,
          deviceStatus,
          serviceStatus,
        };
      });

      let items = [...mappedData];

      if (locationVariable?.value) {
        items = items?.filter(
          (item): boolean => item?.locationId === locationVariable.value
        );
      }

      if (tagLocationsVariable?.length) {
        items = items?.filter((item): boolean => {
          const condition = tagLocationsVariable.some(
            (tag: AutocompleteOptionType) => item?.locationId === tag?.value
          );

          return condition;
        });
      }

      if (
        !locationVariable?.value &&
        tagLocationsVariable &&
        tagLocationsVariable.length === 0
      ) {
        items = [];
      }

      if (!locationVariable?.value && !tagLocationsVariable) {
        items = mappedData;
      }

      if (offlineStatus) {
        items = items?.filter((item): boolean => !item.locationStatus);
      }

      return items.sort((a, b) =>
        a.locationStatus === b.locationStatus ? 0 : a.locationStatus ? 1 : -1
      );
    }, [rows, locationVariable?.value, tagLocationsVariable, offlineStatus]);

    return { data, loading, isHealthDataLoaded };
  };
