import { Box, Slider, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import uuid from "react-uuid";

interface KeyFramePlayerProps {
  urlVideoLink: string;
  state: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
}

const KeyFramePlayer: React.FC<KeyFramePlayerProps> = ({
  urlVideoLink,
  state,
  videoRef,
}) => {
  const [currentTime, setCurrentTime] = useState<number>(0);

  const theme = useTheme();

  const handleTimeSliderChange = (event: Event, value: number | number[]) => {
    const time = Array.isArray(value) ? value[0] : value;

    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }

    setCurrentTime(time);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current) {
        setCurrentTime(videoRef.current.currentTime);
      }
    }, 10);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (value: number) => {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const videoId = uuid();

  return (
    <Box>
      <video
        ref={videoRef}
        id={videoId}
        src={urlVideoLink}
        style={{
          width: "100%",
          borderRadius: "8px",
          overflow: "hidden",
        }}
        controls={false}
      />

      {state && (
        <Slider
          min={0}
          max={videoRef.current?.duration || 100}
          value={currentTime}
          valueLabelDisplay="auto"
          valueLabelFormat={formatTime}
          onChange={handleTimeSliderChange}
          sx={{
            width: "100%",
            color: "primary.main",
            "& .MuiSlider-thumb": {
              backgroundColor: theme.palette.primary.main,
            },
            "& .MuiSlider-rail": {
              backgroundColor: "primary.dark",
            },
          }}
        />
      )}
    </Box>
  );
};

export default KeyFramePlayer;
