import { FC, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { Annotation } from "../../../API";
import HLSStreamPlayer from "../../../pages/data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import PtControllerDialog from "../../../pages/devices/device-details/components/PtControllerDialog";
import { IDeviceTableRow } from "../../../pages/devices/hooks/useDeviceTableRows";
import StyledLoadingButton from "../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import ViewKeyframeWithAnnotations from "../canvas/ViewKeyframeWithAnnotations";
import S3Image from "../media/S3Image";
import WarningMessage from "../stream/WarningMessage";
import LiveViewExpandedDialog from "./LiveViewExpandedDialog";
import ExtendButton from "./MaximizeButton";

export enum ViewModeEnum {
  LIVE,
  KEYFRAME,
  KEYFRAME_WITH_ANNOTATIONS,
}

type ViewMode =
  | ViewModeEnum.LIVE
  | ViewModeEnum.KEYFRAME
  | ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS;

interface KeyframeContainerProps {
  device: IDeviceTableRow;
  s3Key: string;
  showPtButton: boolean;
  annotationsData?: Annotation[];
  defaultViewMode?: ViewMode;
  isKeyframeLoadedDefaultValue?: boolean;
  setKeyframeLoadedHandler?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LiveViewContainer: FC<KeyframeContainerProps> = ({
  device,
  s3Key,
  showPtButton,
  isKeyframeLoadedDefaultValue,
  setKeyframeLoadedHandler,
  annotationsData,
  defaultViewMode = ViewModeEnum.KEYFRAME,
}): JSX.Element => {
  const [viewMode, setViewMode] = useState<ViewMode>(defaultViewMode);
  const [expanded, setExpanded] = useState(false);
  const [ptOpen, setPtOpen] = useState(false);
  const [isKeyframeLoaded, setKeyframeLoaded] = useState(
    !!isKeyframeLoadedDefaultValue
  );

  const onSetIsKeyFrameLoaded = (newValue: boolean) => {
    setKeyframeLoaded(newValue);

    if (setKeyframeLoadedHandler) {
      setKeyframeLoadedHandler(newValue);
    }
  };

  const showKeyFrameWithAnnotations =
    annotationsData &&
    typeof isKeyframeLoaded === "boolean" &&
    !!setKeyframeLoaded;

  const changeViewMode = (): void => {
    setViewMode((prevState): ViewModeEnum => {
      if (prevState !== ViewModeEnum.LIVE) {
        return ViewModeEnum.LIVE;
      }

      if (defaultViewMode === ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS) {
        return ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS;
      }

      return ViewModeEnum.KEYFRAME;
    });
  };

  const changeExpandedMode = (): void => {
    setExpanded((prev): boolean => !prev);
  };

  const openPtController = (): void => {
    setExpanded(false);

    setPtOpen(true);
  };

  const closePtController = (): void => {
    setPtOpen(false);
  };

  const getWidth = (): string => {
    if (viewMode !== ViewModeEnum.LIVE) {
      return isKeyframeLoaded ? "fit-content" : "100%";
    }

    return "100%";
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          position: "relative",
          width: getWidth(),
          minHeight: "376px",
          maxHeight: "500px",
          display: "flex",
          gap: "1em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          border: !isKeyframeLoaded
            ? (theme): string =>
                `1px solid ${theme.palette.otherOutlineBorder.main}`
            : "",
        }}
      >
        {isKeyframeLoaded && (
          <ExtendButton
            title="Maximize"
            changeExpandedMode={changeExpandedMode}
          />
        )}

        <LiveViewExpandedDialog
          viewMode={viewMode}
          expanded={expanded}
          s3Key={s3Key}
          changeViewMode={changeViewMode}
          changeExpandedMode={changeExpandedMode}
          openPtController={showPtButton ? openPtController : undefined}
          isKeyframeLoaded={isKeyframeLoaded}
          setKeyframeLoaded={onSetIsKeyFrameLoaded}
        />

        <PtControllerDialog
          deviceId={device.rowId}
          deviceName={device.name}
          nodeId={device.node.id}
          showPtButton={false}
          ptOpen={ptOpen}
          onPtOpen={openPtController}
          onPtClose={closePtController}
        />

        {viewMode === ViewModeEnum.KEYFRAME && (
          <S3Image
            sx={{
              height: "378px",
              borderRadius: "8px",
            }}
            s3Key={s3Key}
            deviceStatus={device.status}
            isKeyframeLoaded={isKeyframeLoaded}
            setKeyframeLoaded={onSetIsKeyFrameLoaded}
          />
        )}

        {viewMode === ViewModeEnum.LIVE && (
          <Box
            sx={{
              width: "100%",
              height: "378px",
            }}
          >
            <HLSStreamPlayer />
          </Box>
        )}

        {viewMode === ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS &&
          showKeyFrameWithAnnotations && (
            <ViewKeyframeWithAnnotations
              annotationsData={annotationsData}
              isKeyframeLoaded={isKeyframeLoaded}
              keyFrame={s3Key}
              setKeyframeLoaded={onSetIsKeyFrameLoaded}
            />
          )}
      </Box>
      <Grid
        container
        sx={{
          "& button": {
            width: "100%",
          },
        }}
        spacing="1em"
      >
        <Grid item xs={showPtButton ? 6 : 12}>
          <StyledLoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={changeViewMode}
          >
            {viewMode !== ViewModeEnum.LIVE ? "Activate" : "Stop"} live view
          </StyledLoadingButton>
        </Grid>

        {showPtButton && (
          <>
            <Grid item xs={6}>
              <PtControllerDialog
                deviceId={device.rowId}
                deviceName={device.name}
                nodeId={device.node.id}
                showPtButton
                ptOpen={ptOpen}
                onPtOpen={openPtController}
                onPtClose={closePtController}
              />
            </Grid>

            <Grid item>
              <WarningMessage showWarningIcon>
                The model will be stopped when you are running pt control
              </WarningMessage>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default LiveViewContainer;
