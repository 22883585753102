import { FC } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

import useWindowDimensions from "../../common/hooks/useWindowDimensions";

interface VideoDialogProps {
  videoUrl: string | null;
  expanded: boolean;
  toggleExpand: () => void;
}

const VideoDialog: FC<VideoDialogProps> = ({
  videoUrl,
  expanded,
  toggleExpand,
}) => {
  const theme = useTheme();
  const { width: screenWidth } = useWindowDimensions();

  const getMaxWidth = () => {
    if (screenWidth > theme.breakpoints.values.xxl) {
      return theme.breakpoints.values.xl + "px";
    }

    return theme.breakpoints.values.lg + "px";
  };

  const getWidth = () => {
    return expanded ? "100%" : "100%";
  };

  return (
    <Dialog
      fullWidth
      open={expanded}
      onClose={toggleExpand}
      PaperProps={{
        sx: {
          width: getWidth(),
          height: "100%",
          maxWidth: getMaxWidth(),
          borderRadius: "8px",
        },
      }}
    >
      <Box sx={{ position: "absolute", zIndex: 3, right: 8, top: 8 }}>
        <IconButton onClick={toggleExpand}>
          <CloseIcon />
        </IconButton>
      </Box>

      {videoUrl && (
        <video controls autoPlay style={{ width: "100%", height: "100%" }}>
          <source src={videoUrl} type="video/mp4" />
        </video>
      )}
    </Dialog>
  );
};

export default VideoDialog;
