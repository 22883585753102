import React from "react";

import { Box, FormGroup, IconButton, Tooltip } from "@mui/material";

import DeleteIconButton from "../../../common/components/icons/DeleteIconButtonWithText";
import { useServiceActions } from "../hooks/useServiceActions";
import StatusToggle from "../../../common/components/item/StatusToggle";
import { IDeviceTableRow, IModel } from "../hooks/useDeviceTableRows";

type ModelActionControlsProps = {
  device: IDeviceTableRow;
  deviceModel: IModel;
  isModelRunning: boolean;
  setIsModelRunning: (newStatus: boolean) => void;
};

const ModelActionControls: React.FC<ModelActionControlsProps> = ({
  device,
  deviceModel,
  isModelRunning,
  setIsModelRunning,
}): JSX.Element => {
  const deviceDetailPageLink = `/device/${encodeURIComponent(
    device?.rowId ?? ""
  )}`;

  const { changeModelStatusAction, deleteModelAction, serviceLoading } =
    useServiceActions({
      device,
      deviceModel,
    });

  const onChangeModelStatusHandler = (): void => {
    changeModelStatusAction(isModelRunning).then((): void => {
      setIsModelRunning(!isModelRunning);
    });
  };

  const onDeleteModelHandler = (): void => {
    deleteModelAction(deviceDetailPageLink);
  };

  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        gap: "1em",
        alignItems: "center",
        pointerEvents: serviceLoading.commonLoading ? "none" : "auto",
        opacity: serviceLoading.commonLoading ? 0.6 : 1,
      }}
    >
      <IconButton disableRipple onClick={onDeleteModelHandler}>
        <DeleteIconButton />
      </IconButton>

      <FormGroup>
        <Tooltip
          title={
            isModelRunning
              ? "Disable model. In case you disable the model, it won’t be monitored"
              : "Enable model. In case you enable the model, it will be monitored"
          }
          arrow
          placement="top"
        >
          <span>
            <StatusToggle
              loading={serviceLoading.updateStatusLoading}
              checked={!!isModelRunning}
              handleChange={onChangeModelStatusHandler}
            />
          </span>
        </Tooltip>
      </FormGroup>
    </Box>
  );
};

export default React.memo(ModelActionControls);
