import Button, { ButtonProps } from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const ExportDataButton = (props: ButtonProps) => {
  return (
    <Button startIcon={<FileUploadOutlinedIcon />} {...props}>
      Export Data
    </Button>
  );
};

export default ExportDataButton;
