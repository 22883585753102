import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import {
  GetDeviceByIdQuery,
  GetDeviceByIdQueryVariables,
  Service,
} from "../../../API";
import { useGetServicesByCustomer } from "../../../common/components/select/ServiceSelect/useGetServicesByCustomer";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import useLocationFromCache from "../../../common/hooks/useLocationFromCache";
import { GET_DEVICE_BY_ID } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import { useDeploymentHealthTableRows } from "../../deployment-health/hooks/useDeploymentHealthTableRows";
import { deviceFormVariables } from "../variables/devices";
import { IDeviceTableRow, IModel } from "./useDeviceTableRows";
import useGetDeviceMakeByModel from "./useGetDeviceMakeByModel";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";

export interface IJsonData {
  [key: string]: any;
}

const useGetDeviceByUrl = () => {
  const { deviceId = "" } = useParams();

  const customerId = useCustomerIdGuard();
  const { getCachedLocation } = useLocationFromCache();

  const { fetchDeviceMakeByModel } = useGetDeviceMakeByModel();

  const {
    data: healthData,
    loading: dpHealthLoading,
    isHealthDataLoaded,
  } = useDeploymentHealthTableRows();

  const { data: serviceData, loading: getServicesLoading } =
    useGetServicesByCustomer();

  useEffect(() => {
    if (customerId && deviceId && isHealthDataLoaded && !getServicesLoading) {
      fetchDevice();
    }
  }, [customerId, deviceId, isHealthDataLoaded, getServicesLoading]);

  const [getDevice, { data, loading: getDeviceLoading }] = useLazyQuery<
    GetDeviceByIdQuery,
    GetDeviceByIdQueryVariables
  >(GET_DEVICE_BY_ID, {
    fetchPolicy: "network-only",
  });

  const handleRequestError = () => {
    client.cache
      .reset()
      .then(() => {
        errorNotification();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const fetchDevice = () => {
    return getDevice({
      variables: {
        customerId,
        deviceId,
      },
    })
      .then(response => {
        const device = response?.data?.getDeviceById;
        let parsedDeviceData: IJsonData = {};

        if (device) {
          try {
            parsedDeviceData = JSON.parse(device.deviceData ?? "{}");
          } catch (error) {
            console.log("Error while parsing device data", error);
          }

          const sourceVideo =
            parsedDeviceData?.["Source Video"] ??
            parsedDeviceData?.sourceVideo ??
            "";

          const rtspHost =
            parsedDeviceData?.["RTSP Host"] ?? parsedDeviceData?.rtspHost ?? "";

          const panTiltIP =
            parsedDeviceData?.["Pan Tilt IP"] ??
            parsedDeviceData.panTiltIP ??
            "";

          const hasPanTilt =
            parsedDeviceData?.["Pan Tilt"] === "TRUE" ||
            parsedDeviceData?.["panTilt"] === "TRUE";

          parsedDeviceData = {
            ...parsedDeviceData,
            sourceVideo,
            rtspHost,
            panTiltIP,
            hasPanTilt,
          };

          deviceFormVariables({
            isDirty: false,
            cameraIpAddress: device?.cameraIpAddress ?? "",
            deviceData: parsedDeviceData,
            deviceName: device?.name ?? "",
            deviceType: device?.makeModelId ?? "",
            location: {
              value: device?.locationId ?? "",
              title: device?.locationName ?? "",
            },
            node: {
              id: device?.nodeId ?? "",
              name: device?.nodeName ?? "",
            },
          });
        }

        if (response.error) {
          handleRequestError();
        }

        // const item = response?.data?.getDeviceById;
        const locationCache = getCachedLocation(device?.locationId as string);

        const allModels = serviceData?.getServices?.items as Service[];
        const models = allModels?.filter(service => {
          // note: item is device i.e. item.id will always be DE#<>
          // legacy service item's deviceId field has no C# prefix e.g. DE#<>
          const doesLegacyIdsMatch = service?.deviceId === device?.id;
          // service item's deviceId field has C# prefix e.g. C#<>DE#<>; must remove before comparison
          const doesIdsMatch =
            `DE#${service?.deviceId?.split("DE#")[1]}` === device?.id;

          return doesLegacyIdsMatch || doesIdsMatch;
        });

        const devicesFromHealthData = healthData?.find(
          l => l.locationId === device?.locationId
        )?.devices;

        const deviceFromHealthData = devicesFromHealthData?.find(
          deviceHealth => deviceHealth.deviceId === device?.id
        );

        return fetchDeviceMakeByModel(device?.makeModelId as string).then(
          deviceMakeResponse => {
            const result: IDeviceTableRow = {
              rowId: device?.id as string,
              name: device?.name as string,
              location: {
                id: device?.locationId as string,
                name: locationCache?.name ?? "",
              },
              node: {
                id: device?.nodeId as string,
                name: device?.nodeName as string,
              },
              makeModelId: device?.makeModelId as string,
              models:
                models?.map(
                  (service): IModel => ({
                    serviceName:
                      (service?.serviceType as ServiceTypeEnum) ?? "",
                    serviceId: service?.id ?? "",
                    nodeId: service?.nodeId ?? "",
                    isRunning: service?.isRunning ?? false,
                    configuration: service?.configuration ?? "",
                  })
                ) ?? [],

              modelsToRun:
                (deviceMakeResponse?.data?.getDeviceMakeByModel
                  ?.modelsToRun as string[]) ?? [],
              rtspTemplate:
                deviceMakeResponse?.data?.getDeviceMakeByModel.rtspTemplate ??
                "",
              deviceData: parsedDeviceData,
              status: Boolean(deviceFromHealthData?.status),
              cameraIpAddress: device?.cameraIpAddress as string,
            };

            const deviceMakePanTiltValue =
              deviceMakeResponse?.data?.getDeviceMakeByModel.hasPanTilt;

            deviceFormVariables({
              isDirty: false,
              cameraIpAddress: device?.cameraIpAddress ?? "",
              deviceData: parsedDeviceData,
              deviceName: device?.name ?? "",
              deviceType: device?.makeModelId ?? "",
              location: {
                value: device?.locationId ?? "",
                title: device?.locationName ?? "",
              },
              node: {
                id: device?.nodeId ?? "",
                name: device?.nodeName ?? "",
              },
              rtspTemplate:
                deviceMakeResponse?.data?.getDeviceMakeByModel.rtspTemplate ??
                "",
              defaultUserPwd:
                deviceMakeResponse?.data?.getDeviceMakeByModel.defaultUserPwd ??
                "",
              deviceMakePanTiltValue,
            });

            return result;
          }
        );
      })
      .catch(e => {
        console.error(e);

        handleRequestError();
      });
  };

  return {
    data,
    fetchDevice,
    loading: getDeviceLoading,
    getServicesLoading,
    dpHealthLoading,
    isHealthDataLoaded,
  };
};

export default useGetDeviceByUrl;
