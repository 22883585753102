import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import ConfigurationComponent from "../../../common/components/box/ConfigurationComponent";
import WarningBox from "../../../common/components/box/WarningBox";
import FullPageLoader from "../../../common/components/item/FullPageLoader";
import ItemDetailHeader from "../../../common/components/item/ItemDetailHeader";
import ItemDetailTable from "../../../common/components/item/ItemDetailTable";
import LiveViewContainer, {
  ViewModeEnum,
} from "../../../common/components/live-view/LiveViewContainer";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import { useStreamProcesses } from "../../../common/hooks/useStreamProcesses";
import { successNotification } from "../../../common/variables/notification";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";
import { useUpdateService } from "../../model-manager/hooks/useUpdateService";
import { useGetNodeById } from "../../nodes/hooks/useGetNodeById";
import AnnotationContainer from "../annotations/AnnotationContainer";
import { IDeviceTableRow, IModel } from "../hooks/useDeviceTableRows";
import { useGetAnnotations } from "../hooks/useGetAnnotations";
import useGetDeviceByUrl from "../hooks/useGetDeviceByUrl";
import ModelActionControls from "./ModelActionControls";
import MainPaperWrapper from "../../../common/components/item/MainPaperWrapper";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";

const OverviewModelPage = (): JSX.Element => {
  const [deviceDetail, setDeviceDetail] = useState<IDeviceTableRow | null>(
    null
  );
  const [isKeyframeLoaded, setKeyframeLoaded] = useState(false);
  const [isModelRunning, setIsModelRunning] = useState(false);
  const [configurationParams, setConfigurationParams] = useState({
    editMode: false,
    value: "",
  });

  const { fetchDevice, getServicesLoading, loading } = useGetDeviceByUrl();

  const {
    fetchNode,
    data: nodeData,
    loading: getNodeLoading,
  } = useGetNodeById();
  const { updateService } = useUpdateService();
  const { syncShadowsForNode } = useSyncShadow();
  const { deviceId } = useParams();

  let { modelType } = useParams();

  const location = useLocation();

  if (modelType === ServiceTypeEnum.LEAK) {
    modelType = ServiceTypeEnum.GAS_LEAK;
  }

  const { startStreaming, stopStreaming } = useStreamProcesses();

  const modelDetails = useMemo((): IModel | undefined => {
    const model = deviceDetail?.models.find(
      (model): boolean =>
        model.serviceName === modelType || model.serviceName === "Leak"
    );

    if (model?.configuration) {
      setConfigurationParams({
        ...configurationParams,
        value: model.configuration,
      });

      fetchNode(model.nodeId);
    }

    setIsModelRunning(!!model?.isRunning);

    return model;
  }, [deviceDetail]);

  const { data: fetchAnnotationsData } = useGetAnnotations({
    serviceId: modelDetails?.serviceId ?? "",
    deviceId: deviceDetail?.rowId ?? "",
  });

  useEffect((): void => {
    location.state = { deviceId: deviceId };

    fetchDevice().then((device): void => {
      if (device) {
        setDeviceDetail(device);

        startStreaming(device);
      }
    });
  }, [getServicesLoading]);

  useEffect((): void => {
    if (deviceDetail) {
      stopStreaming(deviceDetail);
    }
  }, [deviceDetail]);

  const keyFrameOrRecentFrame = useMemo(() => {
    if (deviceDetail?.deviceData.hasPanTilt) {
      return deviceDetail?.deviceData.recentFrame ?? "";
    }

    return deviceDetail?.deviceData.keyFrame ?? "";
  }, [deviceDetail]);

  const annotationsData = fetchAnnotationsData?.getAnnotations.items;

  const showLoading =
    loading ||
    getServicesLoading ||
    getNodeLoading ||
    !annotationsData ||
    !deviceDetail ||
    !modelDetails;

  if (showLoading) {
    return <FullPageLoader />;
  }

  if (modelType === ServiceTypeEnum.GAS_LEAK) {
    modelDetails.serviceName = ServiceTypeEnum.LEAK;
  }

  const cancelConfigurationEdit = (): void => {
    setConfigurationParams({ ...configurationParams, editMode: false });
  };

  const saveConfigurationEdit = (value: string): void => {
    updateService({
      configuration: value,
      serviceId: modelDetails.serviceId,
      nodeId: modelDetails.nodeId,
    }).finally((): void => {
      syncShadowsForNode(modelDetails.nodeId);

      setConfigurationParams({ value, editMode: false });

      successNotification("Changes applied");
    });
  };

  const setIsModelRunningStatus = (value: boolean): void => {
    setIsModelRunning(value);
  };

  const hasDevicePt = deviceDetail.deviceData?.hasPanTilt;

  const tableData = [
    {
      label: "CONFIGURATION PARAMETERS",
      value: configurationParams.value,
      jsonFormat: true,
      editButton: true,
      rowStyles: { alignItems: "flex-start" },
      onEditHandler: (): void =>
        setConfigurationParams({ ...configurationParams, editMode: true }),
    },
  ];

  const breadcrumbItems = [
    { label: "Devices", path: "/devices" },
    {
      label: deviceDetail.name,
      path: "/device/" + encodeURIComponent(deviceDetail.rowId),
    },
    { label: modelDetails.serviceName },
  ];

  const nodeDetailsRow = [
    {
      label: "NODE",
      value: nodeData?.nodeName ?? "",
    },
  ];

  return (
    <>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <ItemDetailHeader name={modelDetails.serviceName}>
        <ModelActionControls
          device={deviceDetail}
          isModelRunning={isModelRunning}
          setIsModelRunning={setIsModelRunningStatus}
          deviceModel={modelDetails}
        />
      </ItemDetailHeader>

      <MainPaperWrapper>
        <Box
          gap="1.5em"
          sx={{
            "@media (min-width: 1440px)": {
              display: "grid",
              alignItems: "flex-start",
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <Box>
            {!isModelRunning && (
              <Box sx={{ marginBottom: "0.5em" }}>
                <WarningBox>
                  This model isn’t running. To receive all notifications from
                  this model, please enable it
                </WarningBox>
              </Box>
            )}

            <ItemDetailTable data={nodeDetailsRow} />

            {!configurationParams.editMode && (
              <ItemDetailTable data={tableData} />
            )}

            {configurationParams.editMode && (
              <ConfigurationComponent
                model={modelType ?? ""}
                params={configurationParams.value}
                onCancelHandler={cancelConfigurationEdit}
                onSaveHandler={saveConfigurationEdit}
              />
            )}

            {!hasDevicePt && (
              <AnnotationContainer
                deviceId={deviceDetail.rowId}
                nodeId={deviceDetail.node.id}
                annotationsData={annotationsData}
                serviceId={modelDetails.serviceId}
                serviceType={modelDetails.serviceName}
                keyFrame={keyFrameOrRecentFrame}
                isKeyframeLoaded={isKeyframeLoaded}
                setKeyframeLoaded={setKeyframeLoaded}
              />
            )}
          </Box>

          <LiveViewContainer
            s3Key={keyFrameOrRecentFrame}
            device={deviceDetail}
            showPtButton={hasDevicePt}
            annotationsData={annotationsData}
            isKeyframeLoadedDefaultValue={isKeyframeLoaded}
            setKeyframeLoadedHandler={setKeyframeLoaded}
            defaultViewMode={
              hasDevicePt
                ? ViewModeEnum.KEYFRAME
                : ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS
            }
          />
        </Box>
      </MainPaperWrapper>
    </>
  );
};

export default OverviewModelPage;
