import { defaultDataIdFromObject } from "@apollo/client";

import appSyncConfig from "../aws-exports.json";

import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { getJwtToken } from "../common/utils/getJwtToken";

const url =
  "https://vrlfxxflxi.execute-api.us-east-1.amazonaws.com/dev/platform/zclyfutvr5c4xoxsjlgizimk3y";
const region = appSyncConfig.aws_appsync_region;

const httpLink = new HttpLink({
  uri: url,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getJwtToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const auth = {
  type: appSyncConfig.aws_appsync_authenticationType,
  apiKey: "da2-vsii37n5izar7gact7kqubyd3m",
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Path: ${path}, Location:`,
        locations
      )
    );

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([
  authLink,
  createAuthLink({ url, region, auth }),
  errorLink,
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const getMergedItems = refKey => {
  return {
    // NOTE: Don't cache separate results based on any of this field's arguments.
    keyArgs: false,
    merge(existing, incoming, { variables, cache }) {
      if (!incoming) return existing;

      if (!existing) return incoming; // existing will be empty the first time

      // eslint-disable-next-line
      const { items, ...rest } = incoming;

      if (refKey) {
        const refValue = variables[refKey];

        if (refValue) {
          const existingItem = existing?.items
            ? existing?.items[existing?.items?.length - 1]
            : existing[existing.length - 1];
          const resetCache = existingItem?.__ref.indexOf(refValue) === -1;

          if (resetCache) {
            cache.reset();

            return incoming;
          }

          if (existing.items.length && incoming.length === 0) {
            return incoming;
          }
        }
      }

      // NOTE: fixed duplication issues when user goes trough navigation and back to Locations page
      // this a quick solution, bad performance for >10k items
      // possible solution: https://stackoverflow.com/a/68734886/6772862
      const ids = new Set(existing.items.map(d => d.__ref));
      const merged = [
        ...existing.items,
        ...incoming.items.filter(d => !ids.has(d.__ref)),
      ];

      const result = {
        ...rest,
        items: merged,
      };

      return result;
    },
  };
};

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case "Device":
          return `Device:${responseObject.locationId}#${responseObject.id}`;
        case "Zone":
          return `Zone:${responseObject.deviceId}#${responseObject.id}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
    typePolicies: {
      Query: {
        fields: {
          getAllWildCardsForSubscriptionEntity: getMergedItems(),
          getLocations: getMergedItems(),
          getDevices: getMergedItems("locationId"),
          // there is an issue with merging for annotations, where changing query params still shows old results; commenting out merge policy for now
          // getAnnotations: getMergedItems("serviceId"),
          getZones: getMergedItems("serviceId"),
          getDevicesMakes: getMergedItems(),
          getRoles: getMergedItems(),
          getHumanValidatedEventsForAuditing: getMergedItems("customerId"),
          getServices: getMergedItems(),
          getDTCustomerProjects: getMergedItems("customerId"),
          getUsersByCompany: getMergedItems("customerId"),
          getAllAlerts: getMergedItems(),
          getUserAlerts: getMergedItems(),
          getNodes: getMergedItems("locationId"),
          getCategoriesByCustomer: getMergedItems("customerId"),
          getLicensePlatesByCustomer: getMergedItems("customerId"),
        },
      },
    },
  }),
});

export default client;
