import { FC, ReactNode, memo } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import {
  DataGrid,
  GridColDef,
  GridRowHeightReturnValue,
} from "@mui/x-data-grid";

import ExportDataButton from "../../common/components/button/ExportDataButton";
import SimplePaperWrapper from "../../common/components/item/SimplePaperWrapper";
import TableNoDataOverlay from "../../common/components/table/TableNoDataOverlay";
import { dataGridServerStyles } from "../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../common/variables/common";
import useExportGroupData from "../hooks/export/useExportGroupData";
import { LeakFinderTableRow } from "../hooks/useGetVideosTableRows";
import {
  VideosTableSelectionDispatch,
  setVideosAction,
} from "../store/useVideosTableSelectionReducer";
import VideoDialog from "./VideoDialog";

export const TablePaperContainer = ({ children }: { children: ReactNode }) => {
  return (
    <SimplePaperWrapper
      sx={{
        border: 0,
        backgroundColor: "transparent",
      }}
    >
      {children}
    </SimplePaperWrapper>
  );
};

interface IVideosTableProps {
  rows: LeakFinderTableRow[];
  columns: GridColDef[];
  loading: boolean;
  selectedUrlForStream: string | null;
  clientId: string;
  clientName: string;
  groupId: string;
  groupName: string;
  selectedRows: LeakFinderTableRow[];
  dispatch: VideosTableSelectionDispatch;
  onDialogClose: () => void;
}

const VideosTableCompponent: FC<IVideosTableProps> = ({
  rows,
  columns,
  loading,
  selectedUrlForStream,
  onDialogClose,
  clientId,
  clientName,
  groupId,
  groupName,
  selectedRows,
  dispatch,
}) => {
  const rowSelectionModel = selectedRows.map(video => video.id) || [];

  const { exportUploadsData } = useExportGroupData();

  const handleDownloadClick = () => {
    exportUploadsData(rows);
  };

  return (
    <>
      <TableContainer component={TablePaperContainer}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
          }}
        >
          <ExportDataButton
            disableRipple
            variant="text"
            sx={{
              color: theme => theme.palette.text.primary,
              marginRight: 0,
              marginLeft: "auto",
              paddingRight: 0,
            }}
            onClick={handleDownloadClick}
          />
        </Box>
        <DataGrid
          checkboxSelection
          onRowSelectionModelChange={newRowSelectionModel => {
            const checkedRows = rows.filter(row => {
              return newRowSelectionModel.includes(row.id);
            });

            dispatch(
              setVideosAction({
                clientId,
                clientName,
                groupId,
                groupName,
                videos: checkedRows,
              })
            );
          }}
          rowSelectionModel={rowSelectionModel}
          sx={{
            ...dataGridServerStyles.sx,
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "8px",
            },
          }}
          columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
          rows={rows}
          columns={columns}
          slots={{
            loadingOverlay: () => <LinearProgress />,
            noRowsOverlay: TableNoDataOverlay,
          }}
          autoHeight
          getRowHeight={(): GridRowHeightReturnValue => "auto"}
          disableRowSelectionOnClick
          hideFooterSelectedRowCount
          getRowId={(row: { id: string }): string => row.id}
          loading={loading}
          sortingOrder={["desc", "asc"]}
        />
      </TableContainer>

      <VideoDialog
        expanded={!!selectedUrlForStream}
        toggleExpand={onDialogClose}
        videoUrl={selectedUrlForStream}
      />
    </>
  );
};

const VideosTable = memo(VideosTableCompponent, (prevProps, nextProps) => {
  return (
    prevProps.rows.length === nextProps.rows.length &&
    prevProps.loading === nextProps.loading &&
    prevProps.selectedUrlForStream === nextProps.selectedUrlForStream &&
    prevProps.clientId === nextProps.clientId &&
    prevProps.clientName === nextProps.clientName &&
    prevProps.groupId === nextProps.groupId &&
    prevProps.groupName === nextProps.groupName &&
    prevProps.selectedRows.length === nextProps.selectedRows.length
  );
});

export default VideosTable;
