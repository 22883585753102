import { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import {
  UseFieldArrayAppend,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import UploadVideoIcon from "../../../common/components/icons/UploadVideoIcon";
import useUserSettingsFromCache from "../../../common/hooks/useUserSettingsFromCache";
import { DEFAULT_CAMERA_FOV } from "../../hooks/useLazyGetUser";
import { VideoAnalysisFormState } from "../../pages/UploadVideoGroupPage";
import RejectedFilesDialog from "./RejectedFilesDialog";
import UploadsList from "./UploadsList";
import WarningBox from "../../../common/components/box/WarningBox";
import ContactsDialog from "../trial/ContactsDialog";
import { AllowLeakFinder } from "../../../API";
import FullPageLoader from "../../../common/components/item/FullPageLoader";
import { useGetCustomerCounters } from "../../hooks/useGetCustomerCounters";

type VideoDropzoneProps = {
  watch: UseFormWatch<VideoAnalysisFormState>;
  addNewVideoField: UseFieldArrayAppend<VideoAnalysisFormState, "videos">;
  removeVideoField: (index: number) => Promise<void>;
  preventUpload: (state: boolean) => void;
  setFormValue: UseFormSetValue<VideoAnalysisFormState>;
};

const VideoDropzone: React.FC<VideoDropzoneProps> = ({
  addNewVideoField,
  removeVideoField,
  preventUpload,
  watch,
}) => {
  const theme = useTheme();

  const { userSettings } = useUserSettingsFromCache();

  const { customerCounters } = useGetCustomerCounters();

  const files = watch("videos");

  const [openRejectedFilesDialog, setOpenRejectedFilesDialog] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const [openContactsDialog, setOpenContactsDialog] = useState(false);

  const handleContactsDialogClose = (): void => {
    setOpenContactsDialog(false);
  };

  const handleContactsDialogOpen = (): void => {
    setOpenContactsDialog(true);
  };

  let countOfAvailableVideos = 0;
  let textInfoCount = "";
  let showLimitationBox = false;

  if (
    customerCounters &&
    customerCounters?.allowLeakFinder !== AllowLeakFinder.FULL_ACCESS
  ) {
    let allowLeakFinderState = AllowLeakFinder.NONE;

    showLimitationBox = true;

    const {
      usageLeakFinderLimit = 0,
      totalLeakFinderCount = 0,
      allowLeakFinder,
    } = customerCounters;

    if (allowLeakFinder) {
      allowLeakFinderState = allowLeakFinder;
    }

    const usageCount = totalLeakFinderCount ?? 0;

    const totalLimit = usageLeakFinderLimit ?? 0;

    countOfAvailableVideos =
      allowLeakFinderState === AllowLeakFinder.TRIAL_ACCESS
        ? totalLimit - usageCount - (files?.length ?? 0)
        : 0;

    textInfoCount =
      usageCount + (files?.length ?? 0) + "/" + usageLeakFinderLimit;
  }

  const validateCodec = (file: File) => {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file);
      const video = document.createElement("video");

      video.onloadedmetadata = () => {
        resolve(true);

        URL.revokeObjectURL(url);
      };

      video.onerror = () => {
        reject(new Error("Unsupported codec"));

        URL.revokeObjectURL(url);
      };

      video.src = url;
    });
  };

  const createFileRejection = (file: File): FileRejection => ({
    file,
    errors: [
      {
        message: "Unsupported codec",
        code: ErrorCode.FileInvalidType,
      },
    ],
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      const validVideos: File[] = [];
      const invalidVideos: FileRejection[] = [];
      let minCountOfAvailableVideos = Math.min(
        countOfAvailableVideos,
        acceptedFiles.length
      );

      if (customerCounters?.allowLeakFinder === AllowLeakFinder.FULL_ACCESS) {
        minCountOfAvailableVideos = acceptedFiles.length;
      }

      for (let i = 0; i < minCountOfAvailableVideos; i++) {
        try {
          await validateCodec(acceptedFiles[i]);

          validVideos.push(acceptedFiles[i]);
        } catch (error) {
          invalidVideos.push(createFileRejection(acceptedFiles[i]));
        }
      }

      const videos = validVideos.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      videos.forEach(video => {
        addNewVideoField({
          video: video,
          cameraFov: userSettings?.defaultCameraFov ?? DEFAULT_CAMERA_FOV,
        });
      });

      if (fileRejections.length > 0 || invalidVideos.length > 0) {
        setOpenRejectedFilesDialog(true);

        setRejectedFiles([...fileRejections, ...invalidVideos]);
      }
    },
    maxSize: 100000000, // in bytes (100 MB)
  });

  const handleDialogClose = () => {
    setOpenRejectedFilesDialog(false);
  };

  const onClickDropzoneHandler = () => {
    if (
      countOfAvailableVideos <= 0 &&
      customerCounters?.allowLeakFinder !== AllowLeakFinder.FULL_ACCESS
    ) {
      handleContactsDialogOpen();
    }
  };

  const drpopzoneCover = () => {
    if (
      countOfAvailableVideos > 0 ||
      customerCounters?.allowLeakFinder === AllowLeakFinder.FULL_ACCESS
    ) {
      return { ...getRootProps({ className: "dropzone" }) };
    }
  };

  if (!customerCounters) {
    return <FullPageLoader />;
  }

  return (
    <Box>
      <Box onClick={onClickDropzoneHandler} sx={{ cursor: "pointer" }}>
        <RejectedFilesDialog
          open={openRejectedFilesDialog}
          rejectedFiles={rejectedFiles}
          handleClose={handleDialogClose}
        />
        <div {...drpopzoneCover()} style={{ textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              border: `2px dashed ${theme.palette.otherStandardInputLine.main}`,
              borderRadius: "8px",
              background: `${theme.palette.otherBackground.main}`,
            }}
          >
            <input {...getInputProps()} />
            <UploadVideoIcon />
            <Typography
              component="div"
              variant="buttonMedium"
              color="primary.main"
              sx={{
                marginTop: "0.75em",
              }}
            >
              Click to upload videos
            </Typography>
            <Typography component="div" variant="caption">
              or drag and drop
            </Typography>
            <Typography component="div" variant="caption">
              MP4 (max. 100MB)
            </Typography>
          </Box>
        </div>
      </Box>

      <Box sx={{ margin: "0.5em 0" }}>
        {showLimitationBox && (
          <WarningBox>
            <Typography variant="body2Regular" component="p">
              You can upload {countOfAvailableVideos} more free videos.{" "}
              <b>{textInfoCount}</b> are used.{" "}
              <Box
                component="span"
                sx={{
                  fontWeight: "600",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleContactsDialogOpen}
              >
                Contact sales
              </Box>{" "}
              team to get unlimited access.
            </Typography>
          </WarningBox>
        )}
      </Box>

      <ContactsDialog
        open={openContactsDialog}
        preventUpload={preventUpload}
        handleClose={handleContactsDialogClose}
      />

      <UploadsList files={files} removeVideo={removeVideoField} />
    </Box>
  );
};

export default VideoDropzone;
