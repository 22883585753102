export const amplifyConfig = {
  aws_appsync_authenticationType: "API_KEY",
  authenticationFlowType: "CUSTOM_AUTH",
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_XrOtNHSm1",
  aws_user_pools_web_client_id: "4cn4s9jscb8qglhh0dg5bf5tfj",
  aws_cognito_identity_pool_id:
    "us-east-1:36e41360-175b-481d-941c-19bd63dee152",
  aws_appsync_graphqlEndpoint:
    "https://vrlfxxflxi.execute-api.us-east-1.amazonaws.com/dev/platform/zclyfutvr5c4xoxsjlgizimk3y",
  aws_appsync_region: "us-east-1",
  aws_user_files_s3_bucket: "webuser-files-13c3bc4-andrii",
  aws_user_files_s3_bucket_region: "us-east-1",
  aws_node_files_s3_bucket: "nodeuploads-13c3bc4-andrii",
  aws_node_files_s3_bucket_region: "us-east-1",
  aws_leak_finder_s3_bucket: "leakfinder-13c3bc4-andrii",
  aws_leak_finder_s3_bucket_region: "us-east-1",
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_XrOtNHSm1",
    userPoolWebClientId: "4cn4s9jscb8qglhh0dg5bf5tfj",
    identityPoolId: "us-east-1:36e41360-175b-481d-941c-19bd63dee152",
    cookieStorage: {
      domain: ".dev.autonomous365.ai",
      path: "/",
      expires: 1,
      secure: true,
      sameSite: "none",
    },
  },
};
