import { MouseEvent, useState } from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Popover from "@mui/material/Popover";

import { useAmplifyUser } from "../../../hooks/useAmplifyUser";
import LogOutHeaderIcon from "../../icons/LogOutHeaderIcon";
import client from "../../../../configs/apolloClient";
import { Auth } from "aws-amplify";

const AvatarPopover = (): JSX.Element => {
  const { user } = useAmplifyUser();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    Auth.signOut();

    client.resetStore();

    localStorage.clear();
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-avatar-popover" : undefined;

  return (
    <>
      <Button onClick={handleClick} size="small">
        <LogOutHeaderIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          <ListItem sx={{ alignItems: "center", justifyContent: "center" }}>
            <h5>{user?.username}</h5>
          </ListItem>
          <ListItem sx={{ alignItems: "center", justifyContent: "center" }}>
            <Button
              onClick={handleSignOut}
              variant="outlined"
              color="error"
              startIcon={<LogoutIcon />}
            >
              Sign Out
            </Button>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default AvatarPopover;
