import { useNavigate } from "react-router-dom";

import env from "../../../../../environment.json";

export const useTargetDomain = () => {
  const navigate = useNavigate();

  const redirect = () => {
    if (window.location.hostname.includes("localhost")) {
      if (env?.stacks.webapp.domain.subdomains.length) {
        const domain = `${env.name}.${env.stacks.webapp.domain.apex}`;

        navigate(`/login?targetDomain=${domain}`, {
          replace: true,
        });
      } else {
        navigate({ pathname: "/login" });
      }
    } else {
      navigate({ pathname: "/login" });
    }
  };

  return { redirect };
};
